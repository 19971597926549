import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { registerCursos,fetchProjects } from "../actions/ProjectActions";


function Cursosform(props) {
  // const [id_proyecto, setId_proyecto] = useState("");
  const [nombre, setNombre] = useState("");
  const [fecha_nacimiento, setFecha_nacimiento] = useState("");
  const [email, setEmail] = useState("");
  const [telefono, setTelefono] = useState("");
  const [ciudad, setCiudad] = useState("");
  const [domicilio, setDomicilio] = useState("");
  const [projects,setProjects] = useState({});
  const userRegister = useSelector((state) => state.userRegister);
  const { loading,error } = userRegister;
  const dispatch = useDispatch();


  useEffect(() => {
    async function projects() {
      const projects = await fetchProjects(props.match.params.id);
  
      setProjects(projects.data);
    }
    
  }, []);
  console.log(projects.data);
// console.log(props.match.params.id)
  const submitHandler = (e) => {
	e.preventDefault();
  
    dispatch(
      registerCursos(
        // props.match.params.id,
        nombre,
        fecha_nacimiento,
        email,
        telefono
      )
    );
  };

  return (
    <React.Fragment>

      <section className="login">

        <div className="cab-form ">
          <div className="centrar">
              <h2 className="color-white centrar">Inscribite vos también</h2>
              <hr/>
              <h1 className="color-white centrar" >CURSO GRATUITO</h1>
            </div>
            </div>
        <form onSubmit={submitHandler} className="container-login-form">
          <div className="datos-forms">
          <p className="nuestro-juego">
          "Curso de economía PARA TODOS"
                    </p>
           
            {loading && <div>Loading...</div>}
            {error && <div>{error}</div>}


            {/* <input
              ref={inputRef}
              type="hidden"
              id="id_proyecto"
              name="id_proyecto"
              // value={props.match.params.id}
              // value={props.match.params.id}
              onChange={(e) => setId_proyecto(e.target.value)}
            /> */}

            <input
              placeholder="Nombre y Apellido"
              className="input"
              type="nombre"
              name="nombre"
              id="nombre"
              onChange={(e) => setNombre(e.target.value)}
              required
            ></input>
            <input
              placeholder="Fecha de nacimiento"
              className="input"
              type="date"
              name="fecha_nacimiento"
              id="date"
              onChange={(e) => setFecha_nacimiento(e.target.value)}
              required
            ></input>
            

            <input
              placeholder="Correo electrónico"
              className="input"
              type="email"
              name="email"
              id="email"
              onChange={(e) => setEmail(e.target.value)}
              required
            ></input>

            <input
              placeholder="Teléfono"
              className="input"
              type="number"
              id="telefono"
              name="telefono"
              onChange={(e) => setTelefono(e.target.value)}
              required
            ></input>

            {/* <input
              className="input"
              placeholder="Ciudad"
              ref={inputRef}
              type="ciudad"
              id="ciudad"
              name="ciudad"
              //   value={userInfo.id_usuario}
              onChange={(e) => setCiudad(e.target.value)}
            />
            <input
              className="input"
              ref={inputRef}
              placeholder="Dirección"
              type="domicilio"
              id="domicilio"
              name="domicilio"
              //   value={userInfo.id_usuario}
              onChange={(e) => setDomicilio(e.target.value)}
            /> */}

            <button className="naranja-nuevo"><strong style={{color:"white"}}>Confirmar</strong></button>
            <br />
            <br />
          </div>
        </form>
      </section>
    </React.Fragment>
  );
}
export default Cursosform;
