import React, { Component } from "react";
import "../Shopping.css";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Layout from "../Layout/Layout";
import Zoom from "react-reveal/Zoom";
import { connect } from "react-redux";
import { fetchProducts } from "../actions/ProductActions";
import { addToCart } from "../actions/CartActions";
import Modal from "react-modal";
import Promociones from "../components/Promociones";
import MiEspacio from "../Layout/MiEspacio";
import { Helmet } from "react-helmet";

class HomePrincipal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: null,
    };
  }

  openModal = (product) => {
    this.setState({ product });
  };
  closeModal = () => {
    this.setState({ product: null });
  };
  render() {
    const { product } = this.state;
    /**Galeria img Nuestro juego */
    return (
      <div className="fondo-cancha">
        <Layout>
          <Helmet>
            <title>Sintraquiba</title>
            <meta
              name="description"
              content="Nuestra propuesta deportiva asume un enfoque de aprendizaje integral y permite el crecimiento personal de todos los individuos relacionados con el fútbol"
            />
            <meta charset="utf-8" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
            <link rel="icon" href="%PUBLIC_URL%mca/logo-celeste.png" />
            <html lang="es" />
            <meta name="theme-color" content="#000000" />
            <meta name="robots" content="index, follow"></meta>
          </Helmet>
          {/* <MiEspacio /> */}
          <header>


            <div className="centrar">
<p style={{backgroundColor:"black",color:"white", padding:"1rem"}}>SINDICATO NACIONAL DE TRABAJADORES VENDEDORES DE
QUINIELAS, BINGOS, JUEGOS DE AZAR Y AFINES DEL PARAGUA</p>
            </div>
            {/* 
            <div className="container-juego">
              <p className="centrar nuestro-juego">
              "Fortalecimiento de participación democrática:<br/> PROSPERIDAD PARA TODOS"
              </p>
            </div> */}
          </header>
        
          <div style={{backgroundColor:"gray",padding:"1rem",color:"white"}}>
          <h2 style={{color:"white"}}>PRINCIPAL</h2>
          </div>
          <div class="row row2000 menu-309">
            <div class="col">
              <div className="container-menu-redondos11 ">
                <Link to="/donaciones" className="iconos-r-primeros">
                  <img
                    src="/sintraquiba/grupo_430.png"
                    className="iconos-r-primero"
                    alt="dona"
                  />
                </Link>

                <Link to="/proyectos-en-tu-barrio" className="iconos-r-primero">
                  <img
                    src="/sintraquiba/grupo_440.png"
                    className="iconos-r-primero"
                    alt="proyectos"
                  />
                </Link>
                <Link to="/fiscalizacion">
                  <img
                    src="/sintraquiba/grupo_439.png"
                    className="iconos-redondos"
                    alt="fiscaliza."
                  />
                </Link>

              </div>
            </div>
            <div class="col">
              <div className="container-menu-redondos11 ">
                <Link to="/shopping" className="iconos-r-primeros">
                  <img
                    src="/sintraquiba/grupo_432.png"
                    className="iconos-redondos"
                    alt="shopping"
                  />
                </Link>
                <Link to="/voluntariado">
                  <img
                    src="/sintraquiba/grupo_429.png"
                    className="iconos-redondos"
                    alt="voluntarios"
                  />
                </Link>
                <Link to="/contacto" className="iconos-r-primeros">
                  <img
                    src="/sintraquiba/grupo_441.png"
                    className="iconos-redondos"
                    alt="contacto"
                  />
                </Link>
                
              </div>
            </div>

            <div class="col">
              <div className="container-menu-redondos">
                <Link to="/conocenos" className="iconos-r-primeros">
                  <img
                    src="/sintraquiba/grupo_431.png"
                    className="iconos-r-primero"
                    alt="quienes somos"
                  />
                </Link>

                <Link to="/notas" className="iconos-r-primeros">
                  <img
                    src="/sintraquiba/grupo_433.png"
                    className="iconos-redondos"
                    alt="notas"
                  />
                </Link>

              </div>
            </div>
          </div>
          <div>
          <div style={{backgroundColor:"gray",padding:"1rem",color:"white"}}>
          <h2 style={{color:"white"}}>JUGADORES</h2>
          </div>
          <div class="container">

  <div class="row">
    <div class="col">
    <img
                  src="/sintraquiba/grupo_435.png"
                  className="iconos-redondos"
                  alt="voluntarios"
                />


    </div>
    <div class="col">
    <img
                  src="/sintraquiba/grupo_444.png"
                  className="iconos-r-primero"
                  alt="voluntarios"
                />
    </div>
    <div class="col">
    <img
                  src="/sintraquiba/grupo_437.png"
                  className="iconos-r-primero"
                  alt="voluntarios"
                />
    </div>
  </div>
  <div class="row">
    <div class="col">
    <img
                  src="/sintraquiba/grupo_445.png"
                  className="iconos-redondos"
                  alt="voluntarios"
                />


    </div>
    <div class="col">
    <img
                  src="/sintraquiba/grupo_434.png"
                  className="iconos-r-primero"
                  alt="voluntarios"
                />
    </div>
    <div class="col">
    <img
                  src="/sintraquiba/grupo_446.png"
                  className="iconos-r-primero"
                  alt="voluntarios"
                />
    </div>
  </div>
</div>
<br/><br/>


          </div>

          {/* <main>
            <h2 className="centrar">PROMOCIONES</h2>
            <Promociones />
          </main>

<div className="centrar">
          <Link to="/shopping" >
            <button className="button-red-promociones"> Ver todo </button>
          </Link>
          </div>

 */}

          {product && (
            <Modal
              className="my-modal"
              isOpen={true}
              onRequestClose={this.closeModal}
            >
              <Zoom>
                <button className="close-modal" onClick={this.closeModal}>
                  X
                </button>
                <hr />
                <div className="product-details">
                  <img src={product.image} alt={product.name}></img>
                  <div className="product-details-description">
                    <h2>
                      <strong>
                        {product.name}
                        <br />
                        Descripción:{" "}
                      </strong>
                    </h2>

                    <p>${product.description}</p>

                    <div className="product-price">
                      <hr />
                      <h1 className="centrar">RESUMEN</h1>

                      <p className=" centrar price">${product.price}</p>

                      <hr />
                      <div>
                        <h1 className="centrado">PASOS</h1>
                        <ol className="ol">
                          <li>
                            <p>
                              Presiona el botón comprar o seguí agregardo al
                              carrito;
                            </p>
                          </li>
                          <li>
                            <p>
                              Crear tu cuenta, o inicia sesión directamente si
                              ya tienes una;
                            </p>
                          </li>
                          <li>
                            <p>Elije el método de pago de tu preferencia</p>
                          </li>
                          <li>
                            <p>¡Disfruta!</p>
                          </li>
                        </ol>
                        <button className="button-red-forms">Comprar</button>
                        <br />
                        <button
                          className="button-transparente-forms"
                          onClick={() => {
                            this.props.addToCart(product);
                            this.closeModal();
                          }}
                        >
                          Agregar al carrito
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Zoom>
            </Modal>
          )}
        </Layout>
      </div>
    );
  }
}

export default connect((state) => ({ products: state.products.Items }), {
  fetchProducts,
  addToCart,
})(HomePrincipal);
